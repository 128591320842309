// div {
//     --rs-primary-color: #ff0000; /* Replace with your desired primary color */
//   }

  .rs-theme-light, :root {
    --rs-primary-color: #141c25; /* Replace with your desired primary color */

  }

  .rs-picker-toggle-value {
    color: #141c25;
  }
  .rs-picker-toggle-textbox {
    color: #141c25;
  }
  .rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: #141c25;
  }
  .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    background-color: #49535E;
  }
  .rs-btn-link {
    color: #141c25;
  }
  .rs-btn-primary:focus, .rs-btn-primary:hover {
    background-color: #49535E;
    border-color: #49535E;
  }

  .rs-btn-primary:focus, .rs-btn-primary {
    background-color: #49535E;
    border-color: #49535E;
  }
  .rs-picker-menu .rs-calendar .rs-calendar-table-cell-selected:hover .rs-calendar-table-cell-content {
    background-color: #49535E;
  }
  .rs-picker-menu .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
    background-color: #dfeaf7;
  }
  .rs-calendar-table-cell-in-range:before {
    background-color: #dfeaf7;
  }
  .rs-calendar-table-cell-content:hover {
    background-color: #dfeaf7;
  }
  .rs-calendar-table-cell-content:hover {
    background-color: #49535E;
  }
  .rs-picker-menu .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
    color: #141c25;
  }
  .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
    box-shadow: inset 0 0 0 1px #141c25;
  }

  .rs-btn-link.rs-btn-disabled, .rs-btn-link:disabled {
    color: #49535E;
  }
  .rs-calendar-header-error:focus, .rs-calendar-header-error:hover {
    color: #141c25;
  }

  .rs-btn-subtle:focus, .rs-btn-subtle:hover {
    background-color: #dfeaf7;
  }
  .rs-calendar-header-error {
    color: #49535E;

  }

  .rs-calendar-month-view .rs-calendar-header-title-date.rs-calendar-header-error {
    
    color: #49535E;
  }

  .rs-btn-primary.rs-btn-disabled, .rs-btn-primary:disabled {
    background-color: #49535E;
    // border-color: #49535E;
  }