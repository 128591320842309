.config-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* background-color: #1d262f; */
  padding: 5px;
  border-radius: 2px;
  /* box-shadow: 2px 2px 2px rgba(0,0,0,0.2); */
  margin-bottom: 10px;
  /* border: solid #333 1px; */
  /* border-bottom: solid #999 1px; */
  /* margin-top: 10px; */
}

.config-header-title {
  color: #222;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
  margin: 5px;
  font-weight: 400;
  font-size: 27px; 
}
.config-header-description {
  font-size: 16px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.config-header.section {
  /* background-color: #49535D; */
  background-color: var(--quaternary-color);
  margin-bottom: 0px;
  /* color: white; */
  color: var(--quaternary-font-color);
  /* margin-top: 0px; */
  /* font-weight: 600; */
  /* margin-bottom: 5px; */
}

.config-header-title.section {
  /* font-weight: 600; */
  /* color: white; */
  color: var(--quaternary-font-color);
  font-size: 100%;
  /* margin-top: 0px; */
}
.config-header.section.background-color-quinary {
  background-color: var(--quinary-color);
}

/* 
{
  label: "IP Assignment",
  value: conf.ipAssignment,
  inputType: "dropdown",
  options: [{
    title: "DHCP",
    value: "dhcp"
  }, {
    title: "Static",
    value: "static"
  }],
  validation: value => value.length > 0
} */