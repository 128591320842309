
#wrapper{
  position:relative;
  /*background:#333;*/
  /* height:100%; */
}
#wrapper-fixed {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
/* .profile-main-loader{ */
  /* left: 50% !important;
  margin-left:-100px;
  position: fixed !important;
  top: 50% !important;
  margin-top: -100px;
  width: 45px;
  z-index: 9000 !important; */
/* } */

.profile-main-loader .loader {
position: relative;
margin: 0px auto;
/* width: 18px;
height:18px; */
}
.profile-main-loader .loader:before {
content: '';
display: block;
padding-top: 100%;
}

.circular-loader {
-webkit-animation: rotate 2s linear infinite;
        animation: rotate 2s linear infinite;
height: 100%;
-webkit-transform-origin: center center;
    -ms-transform-origin: center center;
        transform-origin: center center;
width: 100%;
position: absolute;
top: 0;
left: 0;
margin: auto;
}

.loader-path {
stroke-dasharray: 150,200;
stroke-dashoffset: -10;
-webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
        animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
stroke-linecap: round;
}

.loader-path-black {
  stroke-dasharray: 150,200;
  stroke-dashoffset: -10;
  stroke-linecap: round;
  -webkit-animation: dash 1.5s ease-in-out infinite, blackcolor 6s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite, blackcolor 6s ease-in-out infinite;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
}

@-webkit-keyframes color {
  0% {
    stroke: white;
  }
  40% {
    stroke: white;
  }
  66% {
    stroke: white;
  }
  80%, 90% {
    stroke: white;
  }

}

@keyframes color {
  0% {
    stroke: white;
  }
  40% {
    stroke: white;
  }
  66% {
    stroke: white;
  }
  80%, 90% {
    stroke: white;
  }
}


@-webkit-keyframes blackcolor {
  0% {
    stroke: black;
  }
  40% {
    stroke: black;
  }
  66% {
    stroke: black;
  }
  80%, 90% {
    stroke: black;
  }

}

@keyframes blackcolor {
  0% {
    stroke: black;
  }
  40% {
    stroke: black;
  }
  66% {
    stroke: black;
  }
  80%, 90% {
    stroke: black;
  }
}
