
.pulse {
	animation: pulse-green 2s 1;
}

@keyframes pulse-green {
	0% {
		transform: scale(0.97);
		box-shadow: 0 0 0 0 rgba(34, 139, 34, 0.7);
	}
	
  100% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(34, 139, 34, 0);
	}
}
