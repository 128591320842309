
.parent {
  column-count: 2;
  column-gap: 10px;
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside:avoid;
  -moz-page-break-inside:avoid;
  page-break-inside: avoid;
  break-inside: avoid-column;
  /* display: inline-block; */
}

@-moz-document url-prefix() {
  .child {
    display: inline-block;
    /* display: -webkit-inline-box; */
    width: 100%;
  }
}

.parent.no-gutter {
  column-gap: 0px;
}

.parent.half-gutter {
  column-gap: 5px;
}


.parent.full {
  column-count: 1;
}

.child.med {
  break-inside: avoid-column;
  width: 100%;
}
/* 
. {
  display: none;
} */
.small-only {
  display: none;
}

.modal {
  width: 420px;
  /* min-width: 200px; */
}
@media screen and (max-width: 1060px) {
  .parent {
    column-count: 1;
  }
  .child.med {
    width: 100%;
    margin: 0px !important; 
  }
}


@media screen and (max-width: 630px) {
  .parent {
    column-count: 1;
  }

  /* . {
    display: block;
  } */
  .small-only {
    display: block;
  }

  .child.med {
    width: 100%;
    margin: 0px !important; 
  }

  .modal {
    /* border: solid green 1px; */
    width: 92vw;
    min-width: 200px;
  }

  /* .grid-row {
    display: none;
  } */

  .large-only {
    display: none;
  }

  .flex-small-column {
    flex-direction: column;
    align-items: flex-start !important;
  }

}

.hidden {
  display: none;
}
