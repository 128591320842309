
.tgl {
  display: none;
}
.tgl, .tgl:after, .tgl:before, .tgl *, .tgl *:after, .tgl *:before, .tgl + .tgl-btn {
  box-sizing: border-box;
}
.tgl::selection, .tgl:after::selection, .tgl:before::selection, .tgl *::selection, .tgl *:after::selection, .tgl *:before::selection, .tgl + .tgl-btn::selection {
  background: none;
}
.tgl + .tgl-btn {
  outline: 0;
  display: block;
  width: 4em;
  height: 2em;
  position: relative;
  cursor: pointer;
  user-select: none;
}
.tgl + .tgl-btn:after, .tgl + .tgl-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
}
.tgl + .tgl-btn:after {
  left: 0;
}
.tgl + .tgl-btn:before {
  display: none;
}
.tgl:checked + .tgl-btn:after {
  left: 50%;
}

/* Default styles for .tgl-btn */
.tgl-light + .tgl-btn {
  background: #dbcece; /* Red color */
  border-radius: 2em;
  padding: 2px;
  transition: all 0.4s ease;
}

.tgl-light + .tgl-btn:after {
  border-radius: 50%;
  background: #fff;
  transition: all 0.2s ease;
}

/* Green color when .tgl-light is checked */
.tgl-light:checked + .tgl-btn {
  background: var(--highlight-color);
}

/* Styles for .tgl-btn when it also has .flipped class */
.tgl-light + .tgl-btn.flipped {
  background: var(--highlight-color); /* Green color */
}

/* Red color when .tgl-light is checked and .tgl-btn has .flipped class */
.tgl-light:checked + .tgl-btn.flipped {
  background: #dbcece; /* Red color */
}