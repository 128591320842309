@import "./foundation.scss";
@import "~foundation-sites/scss/foundation";

@import '~awesome-notifications/dist/style.css';
@import 'rsuite/dist/rsuite.min.css';
// @import '~rsuite/styles/index.less';
@import 'custom-rsuite.scss'; // Style customization.

:root {
  --rs-primary-color: #141c25;
  --rs-picker-value: #141c25;
}


@include foundation-everything(true);
@include foundation-grid;
@include foundation-xy-grid-classes(
  $base-grid: true,
  $margin-grid: true,
  $padding-grid: true,
  $block-grid: true,
  $collapse: true,
  $offset: true,
  $vertical-grid: true
);

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  //   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.background-gradient {
  // background-image: linear-gradient(to right bottom, rgb(19, 54, 85), rgb(74, 109, 140));
  background-image: linear-gradient(to right bottom, var(--secondary-color), var(--tertiary-color));
}
 
.app-item {
  background-color: white;
  box-shadow: 3px 3px 3px rgba(0,0,0,0.1);
  padding: 10px;
  /* filter: invert(80%) hue-rotate(180deg); */
  
  /* margin: 0.5px; */
  // border: solid rgba(0,0,0,0.08) 1px;
}
.app-item.hover:hover {
  box-shadow:0px 0px 1px rgba(0,0,0,1);
  border: solid rgba(0,0,0,0.2) 1px;
}

/* .app-box {
  background-color: white;
  box-shadow:0px 0px 1px rgba(0,0,0,0.3);
  padding: 10px;
  border: solid rgba(0,0,0,0.1) 0.5px;
} */

/* .header-title {
  font-size: 150%;
  font-weight: 800;
  padding: 10px;
  background-color: #49535D;
  color: white;
  border-radius: 2px;
}

.header-title.med {
  font-size: 115%;
  font-weight: 600;
  background-color: #49535d;
  
  color: white;
  border-radius: 2px;
  margin-bottom: 2px;
  margin-top: 10px;
}

.header-title.sml {
  font-size: 105%;
  background-color: #49535D;
  color: white;
  border-radius: 2px;
} */

.minor-text {
  font-size: 80%;
  font-weight: 300;
  display: flex;
  align-items: center;
  font-style: italic;
}
svg > g:last-child > g:last-child { pointer-events: none }


.button {
  cursor: pointer;
  font-size: 14px;
  padding: 10px 30px;
  border-radius: 2px;
  border: solid rgba(0,0,0,0.1) 1px;
  box-shadow: 2px 2px 2px rgba(0,0,0,0.1);
  // background-color: #223647;
  // color: white;
  background-color: var(--highlight-color);
  color: var(--highlight-font-color);
  /* font-family: Ubuntu; */
}


.slide {
  box-sizing: border-box;
  padding: 15px 15px 15px 58px;
  border-radius: 2px;
  color: #fff;
  background-color: #ccc;
  box-shadow: 0 0 4px rgba(255,255,255,0.3);
  cursor: pointer;
  font-size: 1em;
  line-height: 1.2em;
  position: relative;
  opacity: 0.9;
  margin-top: 15px;
}

/* .slide .title {
  font-size: 1em;
  line-height: 1.2em;
  font-weight: bold;
  margin: 0 0 5px 0;
  text-transform: capitalize;
} */

/* .slide:hover, .slide:focus {
  opacity: 1;
}

.slide-enter {
  visibility: hidden;
  transform: translate3d(0, -100%, 0);
}

.slide-enter.slide-enter-active {
  visibility: visible;
  transform: translate3d(0, 0, 0);
  transition: all 0.4s;
}

.slide-leave {
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.slide-leave.slide-leave-active {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
  transition: all 0.4s;
} */
.slide-enter {
  opacity: 0;
  transform: scaleY(0.9);
}
.slide-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.slide-exit {
  opacity: 1;
}
.slide-exit-active {
  opacity: 0;
  transform: scaleY(0.9);
  transition: opacity 300ms, transform 300ms;
}


.c-pill {
  align-items: center;
  font-weight: bold;
  font-size: 11px;
  display: inline-block;
  height: 100%;
  white-space: nowrap;
  width: auto;
  position: relative;
  border-radius: 20px;
  line-height: 1;
  overflow: hidden;
  padding: 10px 20px 10px 30px;
  text-overflow: ellipsis;
  line-height: 1.25rem;
  color: #595959;
  word-break: break-word;
}
.c-pill:before {
  border-radius: 50%;
  content: ''
 ;
  height: 10px;
  left: 10px;
  margin-top: -5px;
  position: absolute;
  top: 50%;
  width: 10px;
}
.c-pill--success {
  background: #b4eda0;
}
.c-pill--success:before {
  background: #6bc167;
}
.c-pill--warning {
  background: #ffebb6;
}
.c-pill--warning:before {
  background: #ffc400;
}
.c-pill--danger {
  background: #ffd5d1;
}
.c-pill--danger:before {
  background: #ff4436;
}

.text-shadow:hover {
  text-shadow: 1px 1px 3px rgba(0,0,0,0.2);
}

input[type='checkbox'] { 
  box-shadow: none !important;

 }


 .section-banner {
  margin-top: 80px; 
  // padding-bottom: 5px;
  border-bottom: solid rgba(0,0,0,0.2) 1px ;
  background-color: #49535E;
  color: white;
  padding: 10px;
  padding-left: 20px;
  border-radius: 3px;
}


.welcome-background {
    height: 117.7vh;
    overflow: auto;
    background-image: linear-gradient(to right bottom, rgb(24 57 86), rgb(104 132 158));
}


.hover-show .hover-item {
  // visibility: hidden;
  opacity: 0;
}
.hover-show:hover .hover-item {
  // visibility: visible;
  opacity: 1;
} 
.hover-item {
  transition: all 0.3s;
}

  // .hover-show :hover {
  //   background-color: solid red 1px;
  // }

  li {
    margin-bottom: 7px;
  }

  