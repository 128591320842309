.pagination {
  display: flex;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}


.pagination>li>a, .pagination>li>span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  /* color: #337ab7; */
  text-decoration: none;
  /* background-color: #fff; */
  border: 1px solid #ddd;
  cursor: pointer;
}

.pagination > li.active > span {
  /* background-color:#49535D; */
  background-color: var(--tertiary-color);
  color: white;
}
.pagination>li>a, .pagination>li>span {
  background-color: #fff;
}


.background-color-primary {
  background-color: var(--primary-color);
  /* color: var(--primary-font-color); */
} 

.background-color-secondary {
  background-color: var(--secondary-color);
  color: var(--secondary-font-color);
}

.background-color-tertiary {
  background-color: var(--tertiary-color);
}
.background-color-quaternary {
  background-color: var(--quaternary-color);
}
.background-color-quinary {
  background-color: var(--quinary-color);
  color: var(--quinary-font-color);
}
.background-color-highlight {
  background-color: var(--highlight-color);
}

.background-color-inactive {
  background-color: #d1d1d1;
}

/* 
ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
} */