.top-header-container {
  background-color: var(--secondary-color);
  padding: 10px;
  position: relative;
  /* display: flex; */
  /* justify-content: space-between; */
  /* margin-left: -240px; */
}
.top-header-container > div {
  display: flex;
  justify-content: center;
  align-items: center;

}
.top-header-container > div > svg {
  fill: white !important;
}

.top-header-container > .top-header-mobile-hamburger {
  /* display: none; */
  cursor: pointer;
  position: absolute;
}

.top-header-container > .top-header-title-section {
  position: absolute;
}

.top-header-title {
  color: rgba(255,255,255,0.95);
}


.App-logo {
  height: 40px;
  width: 40px;
}
.App-header {
  /* background-color: #222; */
  /* height: 100px; */
  padding: 10px;
  padding-top: 0px;
  margin-top: 10px;
  color: white;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* flex-direction: column; */
  margin-bottom: 12px;
}

.App-title {
  font-size: 1.3em;
  font-weight: 600;
  text-align: left;
  color: white;
}


/* SIDE HEADER */
.side-nav-wrapper {
  width: 210px;
  background-color: var(--primary-color);
  position: fixed;
  left: 0px;
  transition: all 0.5s;
  z-index: 2;
  top: 0px;
  bottom:0px;
  overflow: auto;
  
}
.side-nav-container {
  margin: 10px;
  padding-top: 20%;
  padding-bottom: 20%;
}
.side-nav-item {
  padding: 10px 18px;
  box-shadow:0px 0px 1px rgba(0,0,0,0.2);
  margin: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  // color: rgba(255,255,255,0.8);
  color: var(--primary-font-color);
  &:hover {
    // color: white !important;
    color: var(--primary-font-color)
  }

}

.side-nav-item.active {
  color: var(--primary-color);
  background-color: white;
  font-weight:600;
  border-radius: 2px;
  &:hover {
    // color: #141c25 !important;
    color: var(--primary-color) !important;
  }
}
.side-nav-item.active > div > svg {
  fill: var(--primary-color) !important;
}



.section-header {
  border-bottom: solid rgba(0,0,0,0.5) 2px;
  padding: 10px 0px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 17;
}
.section-container {
  padding: 20px 10px 10px 0px;
}

/* MAIN */
.main-section {
  position: relative;
}
.active-page-wrapper {
  position: absolute;
  right: 0;
  left: 210px;
  transition: all 0.5s;
}
.active-page-container {
  margin: 10px;
  display: flex;
  justify-content: center;
}

.active-page-container-content {
  max-width: 1400px;
  width: 100%;
}

.active-page-subheader {
  background-color: var(--tertiary-color);
  /* color: rgba(255,255,255,0.6); */
  color: var(--tertiary-font-color);
  font-size: 16px;
  display: flex;
  padding: 10px;
  flex-wrap: nowrap;
  font-weight: 600;
  overflow: auto;
}

.active-page-subheader-item {
  padding: 5px 10px; 
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 400;
  // color: rgba(255,255,255,0.8);  
  color: var(--primary-font-color);
  &:hover {
    // color: red !important;
    // color: white;
    color: var(--primary-font-color);
  }

}

.active-page-subheader-item > svg {
  margin-right: 1px !important;
}

.active-page-subheader-item.active {
  color: var(--primary-color);
  background-color: white;
  border-radius: 2px;
  box-shadow: 2px 2px 2px rgba(0,0,0,0.15);
}

.active-page-subheader-item.active > svg {
  fill: var(--primary-color) !important;
}

.side-nav-hamburger {
  position: fixed;
  left: 20px;
  top: 10px;
  /* right: 0px; */
  font-size: 40px;
  height: 40px;
  width: 40px;
  cursor: pointer;
  display: none;

  font-weight: normal;
  line-height: 1.5;
  background-color: white;
  justify-content: center;
  align-items: center;

  color: rgba(0, 0, 0, 0.8);
  border-radius: 100%;
  /* padding: 5px; */
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
  z-index:2;
}
.side-nav-cancel {
  position: absolute;
  color: white;
  font-size:28px;
  padding: 8px;
  right: 0px;
  cursor: pointer;
  display: none;
}

@media screen and (max-width: 630px) { /*small*/
  /* body {
    background-color: blue;
  } */
  .side-nav-hamburger {
    display: flex;
  }
  .side-nav-hamburger.disabled {
    display: none;
  }
  .side-nav-wrapper {
    left: -210px;
  }
  .side-nav-cancel {
    display: block;
  }
  /* .side-nav-wrapper.mini {
    left: 75px;
  } */
  .side-nav-wrapper.active {
    left: 0px;
    /* background-color: #222; */
    /* background-color: rgba(0,0,0,0.4); */
  }
  .active-page-wrapper {
    left: 0px;
  }
  /* .top-header-container {
    width: 100vw;
  } */

  .active-page-container {
    margin: 3px;
    padding: 3px;
  }
  .active-page-subheader {
    padding: 5px;
  }
  .active-page-subheader-item {
    font-size: 14px;
    /* padding: 5px 10px; */
  }
}
@media screen and (min-width: 630px) {
  .side-nav-container.mini {
    margin: 1px;
  }
  .main-section.mini > .active-page-wrapper {
    left: 75px;
  }
  .side-nav-item.mini {
    flex-direction: column;
    align-items: center;
  }

  .side-nav-info.mini {
    display: none;
  }
  .side-nav-item.mini > div:nth-child(2) {
    /* color: red; */
    font-size: 11px;
  }
  
  .side-nav-wrapper.mini {
    width: 75px;
  }
  .App-header.mini {
    visibility: hidden;
  }
  .top-header-container > .top-header-mobile-hamburger {
    display: none;
    cursor: pointer;
  }
}
.side-nav-wrapper.mini {
  /* width: 75px; */
  overflow-x: hidden;
}
.row-list-parent:nth-child(odd) > .app-item  {
  background-color: #f3f3f3;
}

@keyframes blink { 
  4% { 
    border: solid maroon 3px;
  } 
}
@keyframes blink-no-top { 
  4% { 
    border-bottom: solid maroon 3px;
    border-left: solid maroon 3px;
    border-right: solid maroon 3px;
    border-top: solid maroon 3px;
    /* border-top: 0px; */
  } 
}
.borderBlink {  
  animation: blink 3s step-end infinite alternate;
  border: solid rgba(0,0,0,0.08) 3px;
  border-radius: 3px;
}

.borderBlink.no-top {
  animation: blink-no-top 3s step-end infinite alternate;
  
  border-bottom: solid solid rgba(0,0,0,0.08) 3px;
  border-left: solid solid rgba(0,0,0,0.08) 3px;
  border-right: solid solid rgba(0,0,0,0.08) 3px;
  border-top: solid solid rgba(0,0,0,0.08) 3px;
  /* border-top: 0px; */
}