#outside-modal {
  background-color: rgba(0,0,0,0.33);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  cursor: pointer;
}

.modal {
  font-size: 15px;
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  padding: 0px;
  border-radius: 3px;
  cursor: default;
  border: none;
  box-shadow: 2px 2px 10px rgba(0,0,0,0.3);
}
.modal > .header-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 6px 10px;
  /* background-color: #49535D; */
  background-color: var(--tertiary-color);
  color: white;
  border-radius: 3px 3px 0px 0px;
}
.modal > .header-section > .header {
  /* width: 100%; */
  /* border-bottom: 1px solid gray; */
  font-size: 18px;
  text-align: center;
  padding: 10px;
  /* background-color: #1d262f; */
  /* color: white; */
  /* box-shadow: 2px 2px 2px rgba(0,0,0,0.1); */
  border-radius: 2px;
}
.modal > .content {
  /* width: 100%; */
  padding: 40px 10px;
  /* border-top: solid 1px rgba(0,0,0,0.1); */
  border-bottom: solid 1px rgba(0,0,0,0.1);
  margin: 5px 10px;
  white-space: pre-line;

}
.modal > .actions {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  /* width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center; */
}
.modal > .header-section > .close {
  cursor: pointer;
  /* position: absolute; */
  /* display: block; */
  padding: 2px 5px;
  /* line-height: 20px; */
  /* right: -10px; */
  /* top: -10px; */
  font-size: 32px;
  /* background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece; */
}
