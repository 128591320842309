

.grid-row {
  // border: solid red 1px;
  display: flex;
  box-shadow: 3px 3px 3px #eee;
  border: solid #eee 1px;
  border-radius: 2px;
  background-color: white;
  margin-bottom: 3px;
  min-width: 1000px;
  // border-right: solid #9cd39c 10px;
  &.connected {
    // border-left: solid #c2dfc2 10px;
    border-left: solid rgb(74, 153, 74) 6px;
    // border-bottom: solid rgb(74, 153, 74) 1px;
    // border: solid rgb(163, 196, 163) 1px;
  }
  &.disconnected {
    // border-left: solid #ebd2d2 10px;
    border-left: solid rgb(146, 46, 46) 6px;
    // border-bottom: solid rgb(146, 46, 46) 1px;
    // border: solid rgb(220, 187, 187) 1px;

  }
}
.grid-row.no-min-width {
  min-width: 0;
}

.grid-row.header  {
  box-shadow: none;
  border-left: none;
  background-color: transparent;
  border: none;
}

.grid-row div {
  /* background-color: white; */
  padding: 10px 6px;
  /* border: solid rgba(0,0,0,0.08) 1px; */
  font-size: 95%;
  display: flex;
  align-items: center;
  /* white-space: nowrap; */
  /* text-overflow: ellipsis; */
  overflow: hidden;
}

.grid-row.header div {
  // background-color: #49535d;
  // color: white;
  color: rgb(107, 107, 107);
  // font-weight: 600;
  /* border: solid rgba(255,255,255,0.2) 1px; */
  font-size: 14px;
}

.grid-row.header.grey div {
  background-color: #6c757e;
}

/* outlet index */
.grid-row div:nth-child(1) {
  flex: 1;
  /* padding-left: 8px; */
  /* border-radius: 3px 0px 0px 3px; */
}
/* outlet id */
.grid-row div:nth-child(2) {
  flex: 4;
}

/* outlet name */
.grid-row div:nth-child(3) {
  flex: 7;
}

/* state */
.grid-row div:nth-child(4) {
  flex: 3;
}

/* power on state */
.grid-row div:nth-child(5) {
  flex: 4;
}

/* bank */
.grid-row div:nth-child(6) {
  flex: 4;
}

/* current */
.grid-row div:nth-child(7) {
  flex: 3;
}

/* watts */
.grid-row div:nth-child(8) {
  flex: 3;
}

/* operation */
.grid-row div:nth-child(9) {
  flex: 4;
  /* border-radius: 0px 3px 3px 0px; */
  padding: 0px;
  /* padding: 2px 6px; */
}

.grid-row.header div:nth-child(10) {
  padding: 6px;
}

.grid-row-wrapper .small-only {
  background-color: white;
}
.grid-row-wrapper .grid-row {
  background-color: white;
  border-radius: 4px;
}
.grid-row-wrapper:nth-child(odd) .grid-row {
  // background-color: #f3f3f3;
}


.skeleton-blank {
  background-color: rgb(230, 230, 230);
  // margin: 8px 5px;
  margin-top: 12px;
  margin-bottom: 12px;
  padding: 6px;
  width: 100%;
  border-radius: 8px !important;
  min-height: 22px;
  display: inline-block;
  // height: 1em;
  position: relative;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
  &.slim {
    margin-top: 3px;
    margin-bottom: 3px;
  }
}


.dot {
  width: 13px;
  height: 13px;
  border-radius: 100%;
    // margin: 20px auto;
    // background-color: #690;
    // border-radius: 50%;
    // box-shadow: rgba(0,0,0,0.03) 0 -1px 7px 1px, inset rgb(34, 139, 34) 0 -1px 2px, rgb(34, 139, 34) 0 2px 4px;
  &.red {
    background-color: maroon;
  }
  &.green {
    background-color: forestgreen;
  }
}

.red-border {
  border: solid red 1px;
}

.spreadsheet-badge {
  flex: none !important; 
  display: flex; 
  background-color: #9cd39c; 
  padding-right: 15px !important;
  // padding: 3px 7px !important; 
  border-radius: 12px;
  white-space: pre;
  // width: 110px;
  box-shadow: 1px 1px 3px rgba(0,0,0,0.1);
  cursor: pointer;
  &:hover {
    box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
    
  }
}

.grid-row-wrapper.disconnected {
  // opacity: 0.7;
  cursor: not-allowed;
}