body {
  margin: 0;
  padding: 0;
  /* font-family: "Ubuntu", sans-serif; */
  /* font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
  /* font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
  /* font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif; */
  /* font-family: 'mulish', 'Ubuntu', 'Roboto', "Trebuchet MS", "Segoe UI", sans-serif; */
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  /* font-size: 95%; */
  background-color: #f3f3f3;
  /* background-color: #2f2f2f; */ 
  color: rgb(77, 77, 77);
  zoom: 85%;
}
  /* GLOBAL */
svg {
  width: 30px;
  fill: rgba(255,255,255,0.75);
  margin-right: 5px;
}

.rs-icon  {
  fill: #666;
}
.material-icons {
  width: 30px;
  fill: rgba(255,255,255,0.75);
  margin-right: 5px;
}
.material-icons.white {
  color: white;
}
/* parent of svg is black*/
.black > svg {
  fill: #666;
}

.dark-grey > svg {
  fill: #888;
}

.grey > svg {
  fill: #acabab;
}
.red > svg {
  fill: maroon;
}

button {
  background-color: white;
  box-shadow: 1px 1px 1px rgba(0,0,0,0.1);
  border: solid grey 1px;
  border-radius: 2px;
  padding: 12px;
  margin: 3px;
}

h2 {
  margin: 0;
  font-weight: 600;
}
h1 {
  font-weight: 600;
}
input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  /* margin: 0 0 1rem; */
  padding: 0.5rem;
  border: 1px solid rgba(0,0,0,0.4);
  border-radius: 3px;
  background-color: #F5F5F5;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}
select:disabled {
  /* background: #dddddd; */
  opacity: 0.5;
}
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  /* height: 2.4375rem; */
  /* margin: 0 0 1rem; */
  padding: 0.5rem;
  border: 1px solid rgba(0,0,0,0.4);
  border-radius: 3px;
  background-color: #F5F5F5;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}


select {
  width: 100%;
  background-color: #F5F5F5;
  display: block;
  box-sizing: border-box;
  height: 2.4375rem;
  /* margin: 0 0 1rem; */
  padding: 0.5rem;
  border: 1px solid rgba(0,0,0,0.4);
  border-radius: 3px;
  /* box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1); */
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  margin: 0px;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}
select.no-margin {
  margin: 0px !important;
}

.special-select {
  /* background: linear-gradient(45deg, transparent 50%, var(--quinary-font-color) 50%),
              linear-gradient(135deg, var(--quinary-font-color) 50%, transparent 50%),
              linear-gradient(to right, var(--quinary-color), var(--quinary-color)); */
  
  /* background-position: calc(100% - 21px) calc(1em + 2px),
                       calc(100% - 16px) calc(1em + 2px),
                       100% 0; */

  /* background-size: 5px 5px,
                   5px 5px,
                   2.5em 2.5em; */

  background-repeat: no-repeat;

  margin: 0;      
  box-sizing: border-box;
  border: solid rgba(0,0,0,0.3) 1px;
  border-radius: 2px;
  
  /* -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-appearance:none;
  -moz-appearance:none; */
  /* background-color: var(--quinary-color); */
  /* color: var(--quinary-font-color); */

}

.special-select:focus {
  outline-offset: -2px;
  border: none;
  outline: solid rgba(0,0,0,0.9) 2px;
 }

.input-container {
  margin: 10px 0px 25px 0px;
}
.input-label {
  /* font-weight: 600; */
  font-size: 16px;
  margin: 10px 0px;
}
.input-description {
  /* font-weight: 600; */
  font-size: 12px;
  margin: 10px 0px;
  color: #666
}
a {
  text-decoration: unset;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #b3b3b3;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #b3b3b3;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #b3b3b3;
}
